import { booksConfig } from '@components/bookpreview/bookConfigs';

import { NarrationsDataFields } from '@constants/datafields';
import { env } from '@constants/env';
import { SearchFilters } from '@constants/filters';

import { z } from 'zod';

export type SearchType = 'phrase' | 'token';

export function appendPrimaryEditionToEditions(obj: {
    book_name: string;
    page: number | null;
    volume: number | null;
    editions: EditionRefs;
}) {
    // append the base edition
    const config = booksConfig[obj.book_name];
    if (config) {
        // book_name sometimes is empty, so there's no corresponding bookConfig
        // unshift so it ends up as the first edition, since it's the primary one
        obj.editions.unshift({
            edition: config.bookCopy,
            page: obj.page,
            volume: obj.volume,
        });
    }
}

export const EditionRefs = z
    .array(
        z.object({
            edition: z.string(),
            volume: z.number().nullable(),
            page: z.number().nullable(),
        }),
    )
    .default([]);

export type EditionRefs = z.infer<typeof EditionRefs>;

export const HadithNumbers = z
    .array(z.number())
    .optional()
    .transform((number) => (number?.length ? number : []));

export const NarratorCommentary = z.object({
    _id: z.string(),
    author: z
        .string()
        .nullable()
        .transform((str) => str ?? ''),
    book: z
        .string()
        .nullable()
        .transform((str) => str ?? ''),
    commenter: z.string(),
    comments: z.string().array(),
    page: z.number().nullable(),
    volume: z.number().nullable(),
});

export type NarratorCommentary = z.infer<typeof NarratorCommentary>;

export const ReferencesSchema = z.object({
    _id: z.string(),
    book_name: z.string(),
    type: z.string(),
    author: z.string(),
    status: z.string(),
    progress: z.number(),
});

export type ReferencesSchema = z.infer<typeof ReferencesSchema>;

export const NarratorBioSchema = z.object({
    _id: z.string(),
    full_name: z.string().optional(),
    extended_fullname: z.string().optional().nullable(),
    nickname: z.string().optional().nullable(),
    origin: z.string().optional().nullable(),
    lived_in: z.string().optional().nullable(),
    died_in: z.string().optional().nullable(),
    died_on: z.string().optional().nullable(),
    born_on: z.string().optional().nullable(),
    level: z.string().optional().nullable(),
    grade: z.string().optional().nullable(),
    book_titles: z.string().array(),
    commentary: z.array(NarratorCommentary).optional(),
    top_scholars: z.string().array(),
    top_students: z.string().array(),
});

export type NarratorBioSchema = z.infer<typeof NarratorBioSchema>;

export const Narrator = z.object({
    full_name: z.string(),
    id: z.string(),
    grade: z.string().nullable(),
    is_companion: z.boolean(),
    is_unknown: z.boolean(),
    reference: z.string(),
});

export const Ruling = z.object({
    ruler: z.string(),
    ruling: z.string(),
    ruler_dod: z.number(),
    book_name: z.string().nullable(),
    page: z.number().nullable(),
    volume: z.number().nullable(),
});

export const AmbiguousWord = z.object({
    id: z.number(),
    book_name: z.string(),
    author: z.string(),
    explanation: z.string(),
    page: z.number().nullable(),
    volume: z.string().nullable(),
});

export const Ambiguous = z.object({
    reference_id: z.string(),
    explanation_ids: z.array(z.number()),
    reference: z.string(),
});

export type Ruling = z.infer<typeof Ruling>;

export type Narrator = z.infer<typeof Narrator>;

export type Ambiguous = z.infer<typeof Ambiguous>;

export type AmbiguousWord = z.infer<typeof AmbiguousWord>;

export const SemanticSearchHadith = z
    .object({
        hadith_id: z.string(),
        hadith_serial_id: z.number(),
        number: HadithNumbers,
        hadith_book_name: z.string(),
        hadith_type: z.enum(['مرفوع', 'قدسي', 'موقوف', 'مقطوع']).optional(),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        narrators: z.array(Narrator),
        narrations: z.string().array(),
        raw_narrations: z.string().array().optional().default([]),
        extended_narrations: z.string().array().optional().default([]),
        narrations_numbers: z
            .array(
                z.object({
                    narration_id: z.string(),
                    number: z.string().array(),
                }),
            )
            .optional()
            .default([]),
        hasExplanation: z.boolean(),
        hasExtendedExplanation: z.boolean(),
        hasCommentary: z.boolean(),
        hasRuling: z.boolean(),
        hasExtendedRuling: z.boolean(),
        chapter: z.string(),
        sub_chapter: z.string().optional().nullable(),
        matn_with_tashkeel: z.string(),
        hadith: z.string(),
        rulings: z.array(Ruling),
        ambiguous: z.array(Ambiguous).default([]),
        _score: z.number(),
        matched_queries: z
            .array(z.enum(['token_query', 'phrase_query']))
            .optional(),
        //TODO [@chammaaomar]: Remove. temporary; for validation puposes
        tag: z.literal('semantic').optional(),
        editions: EditionRefs,
    })
    .transform((hadith) => {
        if (env !== 'prod') {
            hadith.number = [hadith.hadith_serial_id];
        }
        // TODO[@chammaaomar]: remove when backend starts sending raw_narrations
        if (hadith.raw_narrations.length === 0) {
            hadith.raw_narrations = hadith.narrations;
        }
        return {
            ...hadith,
            book_name: hadith.hadith_book_name,
            type: hadith.hadith_type,
            //TODO [@chammaaomar]: Remove. temporary; for validation puposes
            tag: 'semantic',
        };
    });

export type SemanticSearchHadith = z.infer<typeof SemanticSearchHadith>;

export const SearchPageHadith = z
    .object({
        hadith_id: z.string(),
        hadith_serial_id: z.number(),
        number: HadithNumbers,
        book_name: z.string(),
        type: z.enum(['مرفوع', 'قدسي', 'موقوف', 'مقطوع']).optional(),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        narrators: z.array(Narrator),
        raw_narrations: z.string().array(),
        extended_narrations: z.string().array(),
        narrations_numbers: z.array(
            z.object({
                narration_id: z.string(),
                number: z.string().array(),
            }),
        ),
        hasExplanation: z.boolean(),
        hasExtendedExplanation: z.boolean().default(true),
        hasCommentary: z.boolean(),
        hasRuling: z.boolean(),
        hasExtendedRuling: z.boolean().default(true),
        chapter: z.string(),
        sub_chapter: z.string().optional().nullable(),
        matn_with_tashkeel: z.string(),
        hadith: z.string(),
        // _score will be available in the case of inexact search, but absent for exact search
        _score: z.number().optional().nullable(),
        sort: z.array(z.number()).optional(),
        matched_queries: z
            .array(
                z.enum([
                    'token_query',
                    'phrase_query',
                    'non_normalized_phrase_query',
                ]),
            )
            .optional(),
        rulings: z.array(Ruling),
        ambiguous: z.array(Ambiguous).default([]),
        editions: EditionRefs,
    })
    // This should be used whenever `number` is used, for the sake of students in dev
    .transform((obj) => {
        if (env !== 'prod') {
            obj.number = [obj.hadith_serial_id];
        }
        return obj;
    });

export type SearchPageHadith = z.infer<typeof SearchPageHadith>;

export const NarratorsPageNarrator = z.object({
    full_name: z.string(),
    id: z.string(),
    narrations_count: z.string(),
    is_companion: z.boolean(),
    is_unknown: z.boolean(),
});

export type NarratorsPageNarrator = z.infer<typeof NarratorsPageNarrator>;

export interface IDataListItem {
    key: string;
    doc_count: number;
    color?: string;
}

// data quality issue: This should not be missing [@rgb-panda]
export const HadithType = z.enum(['مرفوع', 'قدسي', 'موقوف', 'مقطوع', '']);

export type HadithType = z.infer<typeof HadithType>;

export const ExplanationHadith = z
    .object({
        book_name: z.string(),
        chapter: z.string(),
        hadith: z.string(),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        hadith_id: z.string(),
        hadith_serial_id: z.number(),
        // defaults to empty array
        number: HadithNumbers,
        narrators: z.array(Narrator),
        // domain note: This is indeed optional and is not considered a data quality issue
        sub_chapter: z.string().optional().nullable(),
        type: HadithType.optional(),
        editions: EditionRefs,
    })
    // This should be used whenever `number` is used, for the sake of students in dev
    .transform((obj) => {
        appendPrimaryEditionToEditions(obj);
        if (env !== 'prod') {
            obj.number = [obj.hadith_serial_id];
        }
        return obj;
    });

export type ExplanationHadith = z.infer<typeof ExplanationHadith>;

export const Explanation = z.object({
    explanation_book_author: z.string(),
    explanation_book_name: z.string(),
    hadith_explanation_array: z.array(
        z.object({
            id: z.string(),
            sharh: z.string(),
        }),
    ),
    explanation_page: z.number().nullable().optional(),
    explanation_volume: z.number().nullable().optional(),
});

export type Explanation = z.infer<typeof Explanation>;

export const Commentary = z.object({
    author_name: z.string(),
    book_name: z.string(),
    full_text: z.string(),
    full_text_html: z.string(),
    page: z.number().optional(),
    volume: z.number().optional(),
    commentary_text: z.string(),
    narrations: z.string().array(),
    id: z.number(),
    narrators: z.string().array(),
});

export type Commentary = z.infer<typeof Commentary>;

export const CommentaryHadith = ExplanationHadith;
export type CommentaryHadith = z.infer<typeof CommentaryHadith>;

export const RulerRoadsRulings = z
    .object({
        hadith_id: z.string(),
        ruler: z.string(),
        number: HadithNumbers,
        // numeric string
        ruler_dod: z.number(),
        rulings: z
            .object({
                // a number added manually on the frontend to act as a ruling id, since we don't have
                // an id coming from elastic since the 'rulings' is an array in a document
                // and index changes upon sorting, so we use esIndex as a constant / static index
                esIndex: z.number().optional(),
                volume: z.number().nullable(),
                page: z.number().nullable(),
                book_name: z.string(),
                ruling: z.string(),
                // some rulings are not linked to any road
                hadith_id: z.string().nullable(),
                type: z.enum(['embedded', 'external']),
            })
            .array(),
    })
    .transform((obj) => {
        obj.rulings.forEach((ruling, index) => {
            ruling.esIndex = index;
        });

        return obj;
    });

export type RulerRoadsRulings = z.infer<typeof RulerRoadsRulings>;
export type Rulings = RulerRoadsRulings['rulings'];

export const RulingsHadith = z
    .object({
        book_name: z.string(),
        chapter: z.string(),
        hadith: z.string(),
        // [@chammaaomar]: figure out why /api/hadith-book-preview isn't returning hadith_id and being stupid
        hadith_id: z.string().default(''),
        hadith_serial_id: z.number(),
        number: HadithNumbers,
        narrators: z.array(Narrator),
        raw_narrations: z.string().array(),
        // domain note: This is indeed optional and is not considered a data quality issue
        sub_chapter: z.string().optional().nullable(),
        type: z.enum(['مرفوع', 'قدسي', 'موقوف', 'مقطوع']),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        editions: EditionRefs,
    })
    // This should be used whenever `number` is used, for the sake of students in dev
    .transform((obj) => {
        appendPrimaryEditionToEditions(obj);
        if (env !== 'prod') {
            obj.number = [obj.hadith_serial_id];
        }
        return obj;
    });

export type RulingsHadith = z.infer<typeof RulingsHadith>;

export const Link = z.object({
    src_full_name: z.string(),
    src_grade: z.string().nullable(),
    src_id: z.string(),
    src_reference: z.string(),
    tgt_full_name: z.string(),
    tgt_grade: z.string().nullable(),
    tgt_id: z.string(),
    tgt_reference: z.string(),
    verb: z.string().nullable(),
    // TODO[@chammaaomar]: make non-optional at some point
    src_type: z.enum(['Author', 'Narrator', 'Prophet']).optional(),
    tgt_type: z.enum(['Author', 'Narrator', 'Prophet']).optional(),
});

export type Link = z.infer<typeof Link>;

export const Chain = z.object({
    id: z.string(),
    links: Link.array(),
});

export type Chain = z.infer<typeof Chain>;

// TODO [@rgb-panda, @chammaaomar]: Figure out exactly what this looks like
export const ChainsHadith = z
    .object({
        hadith: z
            .object({
                hadith_id: z.string(),
                number: HadithNumbers,
                hadith_serial_id: z.number(),
                book_name: z.string(),
                // data quality issue: This should not be missing [@rgb-panda]
                type: z
                    .enum(['مرفوع', 'قدسي', 'موقوف', 'مقطوع', ''])
                    .default(''),
                narrators: z.array(Narrator),
                hadith: z.string(),
            })
            .passthrough(),
        chains: Chain.array(),
        hadith_id: z.string(),
        _id: z.string().optional(),
        _ignored: z.any().optional(),
        _score: z.number().optional(),
        _index: z.string().optional(),
    })
    // passthrough because we are writing it as-is to GCS, and want to keep any additional fields that are added in the future
    .passthrough()
    .transform((data) => {
        data.hadith.number = [data.hadith.hadith_serial_id];
        delete data._id;
        delete data._index;
        delete data._ignored;
        delete data._score;
        return data;
    });

export type ChainsHadith = z.infer<typeof ChainsHadith>;

export const NarratorHadith = z
    .object({
        book_name: z.string(),
        chapter: z.string(),
        hadith: z.string(),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        hadith_id: z.string(),
        hadith_serial_id: z.number(),
        // defaults to empty array
        number: HadithNumbers,
        narrators: z.array(Narrator),
        // domain note: This is indeed optional and is not considered a data quality issue
        sub_chapter: z.string().optional().nullable(),
        type: HadithType.optional(),
        raw_narrations: z.string().array(),
        narrations_numbers: z.array(
            z.object({
                narration_id: z.string(),
                number: z.string().array(),
            }),
        ),
        hasExplanation: z.boolean(),
        // TODO [@chammaaomar]: remove the default(true) once the data is there
        hasExtendedExplanation: z.boolean().default(true),
        hasCommentary: z.boolean(),
        hasRuling: z.boolean(),
        // TODO [@chammaaomar]: remove the default(true) once the data is there
        hasExtendedRuling: z.boolean().default(true),
        rulings: z.array(Ruling),
        editions: EditionRefs,
    })
    // This should be used whenever `number` is used, for the sake of students in dev
    .transform((obj) => {
        appendPrimaryEditionToEditions(obj);
        if (env !== 'prod') {
            obj.number = [obj.hadith_serial_id];
        }
        return obj;
    });
export type NarratorHadith = z.infer<typeof NarratorHadith>;

export const RoadsHadith = z
    .object({
        book_name: z.string(),
        chapter: z.string(),
        hadith: z.string(),
        page: z.number().nullable(),
        volume: z.number().nullable(),
        hadith_id: z.string(),
        hadith_serial_id: z.number(),
        // defaults to empty array
        number: HadithNumbers,
        narrators: z.array(Narrator),
        // domain note: This is indeed optional and is not considered a data quality issue
        sub_chapter: z.string().optional().nullable(),
        type: HadithType.optional(),
        matn_with_tashkeel: z.string(),
        raw_narrations: z.string().array(),
        narrations_numbers: z.array(
            z.object({
                narration_id: z.string(),
                number: z.string().array(),
            }),
        ),
        rulings: z.array(Ruling),
        editions: EditionRefs,
    })
    // This should be used whenever `number` is used, for the sake of students in dev
    .transform((obj) => {
        appendPrimaryEditionToEditions(obj);
        if (env !== 'prod') {
            obj.number = [obj.hadith_serial_id];
        }
        return obj;
    });

export type RoadsHadith = z.infer<typeof RoadsHadith>;

export type GenericHadith =
    | SearchPageHadith
    | SemanticSearchHadith
    | ExplanationHadith
    | CommentaryHadith
    | NarratorHadith
    | RoadsHadith
    | RulingsHadith;

export type StatePrefix =
    | ''
    | 'roads_'
    | 'student_'
    | 'narr_student_'
    | 'scholar_'
    | 'narr_scholar_'
    | 'narr_'
    | 'expl_'
    | 'comm_'
    | 'hadith_narr_comm_'
    | 'narr_comm_'
    | 'ref_'
    | 'rul_'
    | 'ambg_';

export type Filter =
    | 'books'
    | 'chapters'
    | 'hadith_types'
    | 'narrators'
    | 'sub_chapters'
    // student and scholar specific filters
    | 'grades'
    | 'hadith_verb';

export type NextState = {
    [key in `${StatePrefix}result`]: {
        hits: {
            hits: {
                _source: SearchPageHadith;
            }[];
            total: number;
        };
        aggregations: {
            // unique_matns: { value: number };
            unique_narrators: { value: number };
            unique_chapters: { value: number };
            unique_sub_chapters: { value: number };
        };
        query: Record<string, any>;
        includeFields: string[];
        react: {
            and: string[];
        };
        sort: unknown;
        highlight: unknown;
        size: number;
    };
} & {
    [key in `${StatePrefix}${Filter}_filters`]: {
        query: Record<string, any>;
        aggs: Record<string, any>;
        size: number;
        value?: string[];
    };
} & {
    // this signature is a subset of the above signature, but is needed because it's more explicit
    [key in SearchFilters]?: {
        query: Record<string, any>;
        aggs: Record<string, any>;
        size: number;
        value?: string[] | string;
    };
};

export const Match = z.object({
    match: z
        .object({
            [NarrationsDataFields.HADITH_TOKENIZED]: z.object({
                query: z.string(),
                _name: z.string(),
            }),
        })
        .optional(),
    match_phrase: z
        .object({
            [NarrationsDataFields.HADITH]: z.object({
                query: z.string(),
                _name: z.string(),
            }),
        })
        .optional(),
});

export type Match = z.infer<typeof Match>;

export const SearchQuery = z.object({
    bool: z.object({
        must: z
            .object({
                bool: z.object({
                    must: z
                        .tuple([
                            z.object({
                                bool: z.object({
                                    should: z
                                        .tuple([
                                            z.object({
                                                match_phrase: z.object({
                                                    [NarrationsDataFields.HADITH]:
                                                        z.object({
                                                            query: z.string(),
                                                            _name: z.string(),
                                                        }),
                                                }),
                                            }),
                                        ])
                                        .rest(z.any()),
                                    minimum_should_match: z.number(),
                                }),
                            }),
                        ])
                        .rest(z.any()),
                }),
            })
            .array(),
    }),
});

export type SearchQuery = z.infer<typeof SearchQuery>;

export const GeneralQuery = z.object({
    bool: z.object({
        must: z
            .object({
                bool: z.object({
                    must: z
                        .object({
                            bool: z.object({
                                // for 'OR'
                                should: z.object({}).array().optional(),
                                // for 'AND'
                                must: z.object({}).array().optional(),
                                minimum_should_match: z.number(),
                            }),
                        })
                        .array(),
                }),
            })
            .array(),
    }),
});

export type GeneralQuery = z.infer<typeof GeneralQuery>;

export type BookReference = {
    page: number;
    volume: number;
    book_name: string;
    editionsRefs: EditionRefs;
    id: string;
    label: string;
    // for ListSubheader functionality, i.e., for grouping related options in the <Select>
    group?: string;
};

export type NarrationsType = 'shawahed' | 'roads' | 'all';
